import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import VerifiedUser from '@mui/icons-material/DevicesRounded';
import Gavel from '@mui/icons-material/EdgesensorHighRounded';
import Visibility from '@mui/icons-material/ViewQuiltRounded';

const items = [
  {
    icon: <Visibility />,
    title: 'Transparency',
    description:
      'We prioritize transparency by openly sharing our financial records, activities, and outcomes with stakeholders. This integrity ensures accountability and trust among donors, beneficiaries, and the public, fostering confidence in our mission and impact.',
    // imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
    // imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
  },
  {
    icon: <Gavel />,
    title: 'Independence',
    description:
      'We maintain independence from government, corporate, or other external influences to safeguard our autonomy and integrity.This independence enables us to advocate for marginalized communities, address systemic issues, and pursue long-term sustainable solutions without bias or conflict of interest.',
    // imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
    // imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
  },
  {
    icon: <VerifiedUser />,
    title: 'Accountability',
    description:
      'We uphold accountability by adhering to ethical standards, legal regulations, and best practices in our operations. We take responsibility for our actions and decisions, ensuring that resources are used efficiently and effectively to fulfill our social or environmental objectives.',
    // imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
    // imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
  },
];

export default function Features() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 8, sm: 16 } }}>
      <Grid container spacing={6}>
        <Grid item xs={12} md={6}>
          <div>
            <Typography component="h2" variant="h4" color="primary.light">
              Our Intrigrity
            </Typography>
            {/* <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 2, sm: 4 } }}
            >
              Here you can provide a brief overview of the key features of the
              product. For example, you could list the number of features, the types
              of features, add-ons, or the benefits of the features.
            </Typography> */}
          </div>
          <Grid container item gap={1} sx={{ display: { xs: 'auto', sm: 'none' } }}>
            {items.map(({ title }, index) => (
              <Chip
                key={index}
                label={title}
                onClick={() => handleItemClick(index)}
                sx={{
                  color: 'primary.light',
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'primary.light' : '';
                    }
                    return selectedItemIndex === index ? 'primary.light' : '';
                  },
                  background: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index ? 'none' : '';
                    }
                    return selectedItemIndex === index ? 'none' : '';
                  },
                  backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                  '& .MuiChip-label': {
                    color: selectedItemIndex === index ? '#fff' : '',
                  },
                }}
              />
            ))}
          </Grid>
          <Box
            component={Card}
            variant="outlined"
            sx={{
              display: { xs: 'auto', sm: 'none' },
              mt: 4,
            }}
          >
            <Box
            // sx={{
            //   backgroundImage: (theme) =>
            //     theme.palette.mode === 'light'
            //       ? items[selectedItemIndex].imageLight
            //       : items[selectedItemIndex].imageDark,
            //   backgroundSize: 'cover',
            //   backgroundPosition: 'center',
            //   minHeight: 280,
            // }}
            />
            <Box sx={{ px: 2, pb: 2 }}>
              <Typography color="primary.main" variant="body2" fontWeight="bold">
                {selectedFeature.title}
              </Typography>
              <Typography color="text.secondary" variant="body2" sx={{ my: 0.5 }}>
                {selectedFeature.description}
              </Typography>
              {/* <Link
                color="primary"
                variant="body2"
                fontWeight="bold"
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  '& > svg': { transition: '0.2s' },
                  '&:hover > svg': { transform: 'translateX(2px)' },
                }}
              >
                <span>Learn more</span>
                <ChevronRightRoundedIcon
                  fontSize="small"
                  sx={{ mt: '1px', ml: '2px' }}
                />
              </Link> */}
            </Box>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
            useFlexGap
            sx={{ width: '100%', display: { xs: 'none', sm: 'flex' } }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Card
                key={index}
                variant="outlined"
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={{
                  p: 3,
                  height: 'fit-content',
                  width: '100%',
                  background: 'none',
                  backgroundColor:
                    selectedItemIndex === index ? 'action.selected' : undefined,
                  borderColor: (theme) => {
                    if (theme.palette.mode === 'light') {
                      return selectedItemIndex === index
                        ? 'primary.light'
                        : 'grey.200';
                    }
                    return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                  },
                }}
              >
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    textAlign: 'left',
                    flexDirection: { xs: 'column', md: 'row' },
                    alignItems: { md: 'center' },
                    gap: 2.5,
                  }}
                >
                  <Box
                    sx={{
                      color: (theme) => {
                        if (theme.palette.mode === 'light') {
                          return selectedItemIndex === index
                            ? 'primary.main'
                            : 'grey.300';
                        }
                        return selectedItemIndex === index
                          ? 'primary.main'
                          : 'grey.700';
                      },
                    }}
                  >
                    {icon}
                  </Box>
                  <Box sx={{ textTransform: 'none' }}>
                    <Typography
                      color='primary.main'
                      variant="body2"
                      fontWeight="bold"
                    >
                      {title}
                    </Typography>
                    <Typography
                      color="text.secondary"
                      variant="body2"
                      sx={{ my: 0.5 }}
                    >
                      {description}
                    </Typography>
                    {/* <Link
                      color="primary"
                      variant="body2"
                      fontWeight="bold"
                      sx={{
                        display: 'inline-flex',
                        alignItems: 'center',
                        '& > svg': { transition: '0.2s' },
                        '&:hover > svg': { transform: 'translateX(2px)' },
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    >
                      <span>Learn more</span>
                      <ChevronRightRoundedIcon
                        fontSize="small"
                        sx={{ mt: '1px', ml: '2px' }}
                      />
                    </Link> */}
                  </Box>
                </Box>
              </Card>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}
        >
          {/* <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
            }}
          >
            
            <Box
              sx={{
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: (theme) =>
                  theme.palette.mode === 'light'
                    ? items[selectedItemIndex].imageLight
                    : items[selectedItemIndex].imageDark,
              }}
            />
          </Card> */}
          <Card
            variant="outlined"
            sx={{
              height: '100%',
              width: '100%',
              display: { xs: 'none', sm: 'flex' },
              pointerEvents: 'none',
              flexDirection: 'column',
              padding: '20px', // Adding padding for spacing between topics and paragraphs
            }}
          >
            <div>
              <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '12px', color: 'primary.main' }}>
                Vision of Nabodito
              </Typography>

              <Typography variant="body1">It is a dream to reach the summit of a mountain, driven by noble intentions to plant our flag atop that peak. Baranagar Nabodito will unite, celebrating success with heartfelt inspiration. Standing alongside people, engaging in social work, is an ongoing journey. With support from more special souls and the blessings of the Almighty, Baranagar Nabodito believes that reaching the summit is not far. It will serve as an example and inspiration for the next generation, encouraging them to step forward. Thus, the organization can perpetuate its social work from one generation to the next.</Typography>
            </div>
            <br></br>

            <div>
              <Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 'bold', marginBottom: '12px', color: 'primary.main' }}>
                Vision of Nabodito
              </Typography>

              <Typography variant="body1">Behind every celebration of success lie small steps that lead to it. Baranagar Nabodito has already taken these steps. In this journey, even to the foothills of a towering mountain, the organization has organized blood donation camps and eye tests for blood pressure and sugar levels several times. For the past twelve years, it has provided new clothes to marginalized communities during the Puja festival and winter season. In its efforts to expand activities, Baranagar Nabodito continuously explores new innovative initiatives, alongside continuing with its past endeavors despite limited funding.</Typography>
            </div>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}