import React from "react";
import { Box } from "@mui/material";
import qr from "../assets/qr.jpeg";

const DonationCard = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" }, // Column on small screens, row on medium and larger screens
        paddingLeft: "50px",
        paddingRight: "50px",
      }}
    >
      <Box
        sx={{
          flex: 1,
          backgroundColor: "#f0f0f0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "hidden",
        }}
      >
        <img
          src={qr}
          alt="QR Code"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "contain", // Ensures the entire image fits within the container
          }}
        />
      </Box>
      <Box
        sx={{
          flex: 2,
          backgroundColor: "#d0d0d0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <div>
          <p>
            <strong>Appeal for donation</strong>
          </p>
          <p>
            Who does not love to bring a smile to the innocent face of children roaming on the street? And not to forget contributing towards the freedom and success of the young...
            There is a warm glow which you shall get if you give your blissful mind. Having the power to improve the lives of others is a privilege. Your act of kindness not only benefits the charities themselves, it can be deeply rewarding for you too. The positive effects and the deep satisfaction and happiness of the act of giving generates, is simply unparalleled, with the spiritual upliftment.
            We, BARANAGAR Nabodito requesting you to support our organisation.
            Nabodito, shows a path of light from darkness.
            We are a charitable non-profit organisation that specialized in the education and empowerment of less privileged children and holistic nurturing of the children of the economically weaker strata of society.
            Apart from this, we also toil at running meaningful collaboration and community programs for community development like cleanliness drives, tree plantation, blood donation camps, health and eye check-up camps, etc., to raise awareness.
          </p>
        </div>
      </Box>
    </Box>
  );
};

export default DonationCard;
