import { Link, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';

const Footer = () => {
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        py: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'center' }, // Center align for md and sm
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center', // Align items vertically
        }}
      >
        {/* Address */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton color="inherit" href="https://yourwebsite.com/address">
            <LocationOnIcon />
          </IconButton>
          <Typography variant="body2" color="text.secondary">
            52/13 , SHASHI BHUSHAN NEOGI GARDEN LANE, BARANAGAR, KOLKATA - 700036
          </Typography>
        </Box>
        {/* Phone Number */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PhoneIcon /> {/* Phone icon without any link */}
          <Typography variant="body2" color="text.secondary">
            Call Us: +91 98040 01818
          </Typography>
        </Box>
      </Box>
      {/* Copyright */}
      <Box
        sx={{
          pt: { xs: 4, sm: 8 },
          borderTop: '1px solid',
          borderColor: 'divider',
          textAlign: 'center',
        }}
      >
        <Typography variant="body2" color="text.secondary" mt={1}>
          {'Copyright © '}
          <Link href="#">Nabodito&nbsp;</Link>
          {new Date().getFullYear()}
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;