import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';


import pic20121 from '../assets/gallary/2012/web/1.JPG';
import pic20122 from '../assets/gallary/2012/web/2.JPG';
import pic20123 from '../assets/gallary/2012/web/3.JPG';
import pic20124 from '../assets/gallary/2012/web/4.JPG';
import pic20125 from '../assets/gallary/2012/web/5.JPG';
import pic20126 from '../assets/gallary/2012/web/6.JPG';
import pic20127 from '../assets/gallary/2012/web/7.JPG';
import pic20128 from '../assets/gallary/2012/web/8.JPG';

import pic20131 from '../assets/gallary/2013/web/1.JPG';
import pic20132 from '../assets/gallary/2013/web/2.JPG';
import pic20133 from '../assets/gallary/2013/web/3.JPG';
import pic20134 from '../assets/gallary/2013/web/4.JPG';
import pic20135 from '../assets/gallary/2013/web/5.JPG';
import pic20136 from '../assets/gallary/2013/web/6.jpg';
import pic20137 from '../assets/gallary/2013/web/7.jpg';
import pic20138 from '../assets/gallary/2013/web/8.JPG';
import pic20139 from '../assets/gallary/2013/web/9.jpg';

import pic20141 from '../assets/gallary/2014/web/1.JPG';
import pic20142 from '../assets/gallary/2014/web/2.JPG';
import pic20143 from '../assets/gallary/2014/web/3.JPG';
import pic20144 from '../assets/gallary/2014/web/4.JPG';
import pic20145 from '../assets/gallary/2014/web/5.JPG';
import pic20146 from '../assets/gallary/2014/web/6.JPG';
import pic20147 from '../assets/gallary/2014/web/7.JPG';
import pic20148 from '../assets/gallary/2014/web/8.JPG';
import pic20149 from '../assets/gallary/2014/web/9.jpg';
import pic201410 from '../assets/gallary/2014/web/10.jpg';
import pic201411 from '../assets/gallary/2014/web/11.jpg';

import pic20151 from '../assets/gallary/2015/web/1.JPG';
import pic20152 from '../assets/gallary/2015/web/2.JPG';
import pic20153 from '../assets/gallary/2015/web/3.JPG';
import pic20154 from '../assets/gallary/2015/web/4.JPG';
import pic20155 from '../assets/gallary/2015/web/5.JPG';
import pic20156 from '../assets/gallary/2015/web/6.JPG';
import pic20157 from '../assets/gallary/2015/web/7.JPG';
import pic20158 from '../assets/gallary/2015/web/8.jpg';

import pic20161 from '../assets/gallary/2016/web/index.jpg';
import pic20162 from '../assets/gallary/2016/web/index1.jpg';
import pic20163 from '../assets/gallary/2016/web/index3.jpg';
import pic20164 from '../assets/gallary/2016/web/PIC (1) YEAR 2016.jpg';
import pic20165 from '../assets/gallary/2016/web/PIC (2) YEAR 2016.jpg';
import pic20166 from '../assets/gallary/2016/web/PIC (3) YEAR 2016.jpg';
import pic20167 from '../assets/gallary/2016/web/PIC (4) YEAR 2016.jpg';
import pic20168 from '../assets/gallary/2016/web/PIC (5) YEAR 2016.jpg';
import pic20169 from '../assets/gallary/2016/web/Scan2.jpg';

import pic20171 from '../assets/gallary/2017/web/1.jpeg';
import pic20172 from '../assets/gallary/2017/web/2.jpeg';
import pic20173 from '../assets/gallary/2017/web/3.jpeg';
import pic20174 from '../assets/gallary/2017/web/4.jpeg';
import pic20175 from '../assets/gallary/2017/web/5.jpeg';
import pic20176 from '../assets/gallary/2017/web/6.jpeg';
import pic20177 from '../assets/gallary/2017/web/7.jpeg';
import pic20178 from '../assets/gallary/2017/web/8.jpeg';
import pic20179 from '../assets/gallary/2017/web/9.jpeg';
import pic201710 from '../assets/gallary/2017/web/10.jpg';

import pic20191 from '../assets/gallary/2019/web/1.JPG';
import pic20192 from '../assets/gallary/2019/web/2.JPG';
import pic20193 from '../assets/gallary/2019/web/3.JPG';
import pic20194 from '../assets/gallary/2019/web/4.JPG';
import pic20195 from '../assets/gallary/2019/web/5.JPG';
import pic20196 from '../assets/gallary/2019/web/6.JPG';
import pic20197 from '../assets/gallary/2019/web/7.JPG';
import pic20198 from '../assets/gallary/2019/web/8.JPG';
import pic20199 from '../assets/gallary/2019/web/9.JPG';
import pic201910 from '../assets/gallary/2019/web/10.JPG';
import pic201911 from '../assets/gallary/2019/web/11.JPG';
import pic201912 from '../assets/gallary/2019/web/12.JPG';


import pic20201 from '../assets/gallary/2020/web/1.JPG';
import pic20202 from '../assets/gallary/2020/web/2.JPG';
import pic20203 from '../assets/gallary/2020/web/3.JPG';
import pic20204 from '../assets/gallary/2020/web/4.JPG';
import pic20205 from '../assets/gallary/2020/web/5.JPG';
import pic20206 from '../assets/gallary/2020/web/6.JPG';
import pic20207 from '../assets/gallary/2020/web/7.JPG';
import pic20208 from '../assets/gallary/2020/web/8.JPG';
import pic20209 from '../assets/gallary/2020/web/9.JPG';
import pic202010 from '../assets/gallary/2020/web/10.JPG';

import pic20211 from '../assets/gallary/2021/web/1.JPG';
import pic20212 from '../assets/gallary/2021/web/2.JPG';
import pic20213 from '../assets/gallary/2021/web/3.JPG';
import pic20214 from '../assets/gallary/2021/web/4.JPG';
import pic20215 from '../assets/gallary/2021/web/5.JPG';
import pic20216 from '../assets/gallary/2021/web/6.JPG';
import pic20217 from '../assets/gallary/2021/web/7.JPG';
import pic20218 from '../assets/gallary/2021/web/8.JPG';
import pic20219 from '../assets/gallary/2021/web/9.JPG';

import pic20221 from '../assets/gallary/2022/web/1.jpg';
import pic20222 from '../assets/gallary/2022/web/2.jpg';
import pic20223 from '../assets/gallary/2022/web/3.jpg';
import pic20224 from '../assets/gallary/2022/web/4.jpg';
import pic20225 from '../assets/gallary/2022/web/5.jpg';




const imagesByYear = {
  2012: [pic20121, pic20122, pic20123, pic20124, pic20125, pic20126, pic20127, pic20128],
  2013: [pic20131, pic20132, pic20133, pic20134, pic20135, pic20136, pic20137, pic20138, pic20139],
  2014: [pic20141, pic20142, pic20143, pic20144, pic20145, pic20146, pic20147, pic20148, pic20149, pic201410, pic201411],
  2015: [pic20151, pic20152, pic20153, pic20154, pic20155, pic20156, pic20157, pic20158],
  2016: [pic20161, pic20162, pic20163, pic20164, pic20165, pic20166, pic20167, pic20168, pic20169],
  2017: [pic20171, pic20172, pic20173, pic20174, pic20175, pic20176, pic20177, pic20178, pic20179, pic201710],
  2019: [pic20191, pic20192, pic20193, pic20194, pic20195, pic20196, pic20197, pic20198, pic20199, pic201910, pic201911, pic201912],
  2020: [pic20201, pic20202, pic20203, pic20204, pic20205, pic20206, pic20207, pic20208, pic20209, pic202010],
  2021: [pic20211, pic20212, pic20213, pic20214, pic20215, pic20216, pic20217, pic20218, pic20219],
  2022: [pic20221, pic20222, pic20223, pic20224, pic20225],
};
const Highlights = () => {
  const [selectedYear, setSelectedYear] = React.useState('2012');
  const [loading, setLoading] = React.useState(true);

  const handleYearClick = (year) => {
    setLoading(true);
    setSelectedYear(year);
  };

  React.useEffect(() => {
    // Simulating image loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000); // Adjust the timeout value as needed for your actual image loading time

    // Cleanup function to clear timeout in case component unmounts
    return () => clearTimeout(timeout);
  }, [selectedYear]);
  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Gallery
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Discover our latest initiatives aimed at fostering sustainable development and empowering communities. From innovative education programs to environmental conservation efforts, we're committed to making a positive impact. Join us in creating a brighter future for all.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {Object.keys(imagesByYear).map((year) => (
            <Grid item xs={12} sm={6} md={1} key={year}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: year === selectedYear ? 'primary.main' : 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                  cursor: 'pointer',
                }}
                onClick={() => handleYearClick(year)}
              >
                <Typography fontWeight="medium" gutterBottom>
                  {year}
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <CircularProgress color="primary" />
          </Box>
        ) : (
          <Grid container spacing={2}>
            {imagesByYear[selectedYear].map((imageUrl, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card sx={{ maxWidth: 345 }}>
                  <img src={imageUrl} alt={'alt'} style={{ width: '100%' }} />
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Highlights;