import './App.css';
import LandingPage from './LandingPage.js';


function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;