import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { List, ListItem, ListItemText } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function FAQ() {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        color="text.primary"
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How can I get involved or volunteer?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              You can reach our customer support team by emailing
              <Link> contact@baranagarnabadito.com </Link> or calling us at +91 98040 01818.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
              How can I donate or support your cause?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              <List>
                <ListItem>
                  <ListItemText primary="UPI:" secondary="Please contact with any of our memebrs." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Bank Transfer:" secondary="Transfer funds directly to our account." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Campaigns:" secondary="Join our fundraising events or start your own." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="In-Kind:" secondary="Donate goods or services." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Your support fuels our mission. Thank you!" />
                </ListItem>
              </List>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
            What impact we have made?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              We have positively impacted countless lives through our various programs and initiatives. Our efforts have resulted in improved access to education, healthcare, and basic necessities for communities in need. Additionally, we've raised awareness about important social issues and empowered individuals to create sustainable change. Through these achievements, we continue to foster resilience and hope within the communities we serve.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
            How do we ensure transparency and accountability?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '70%' } }}
            >
              Transparency and accountability are at the core of our operations. We maintain open communication channels with our stakeholders, providing regular updates on our activities, finances, and outcomes. Our financial records undergo thorough audits by reputable firms to ensure accuracy and compliance with regulatory standards. Additionally, we have established governance structures and policies that promote ethical conduct and responsible decision-making. By upholding these principles, we strive to earn the trust and confidence of our supporters, partners, and the communities we serve.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}